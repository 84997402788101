// @todo enable next eslint rules
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'gatsby';
import VerticalTextBlock from '../VerticalTextBlock';

import './index.scss';


/**
 * @todo escape special characters
 * @example
 * <h1>
 *   &lt;&lt;&lt;John&apos;s component &quot;Rock&amp;Rolls&quot;&gt;&gt;&gt;
 * </h1>
 */
const OurFeaturesBlock = () => (
  <section className="features-section">
    <div className="features-section__container container">
      <VerticalTextBlock
        sectionClassName="features"
        text="Our features"
        dotsStyle="style-3"
      />

      <div className="features-section__content">

        <div className="features-section__heading">
          <h2 className="features-section__heading__text">
            We've got everything to become your favourite writing service
          </h2>
        </div>

        <Link
          to="/money-back-guarantee.html"
          className="features-section__link-block features-section__guarantee"
          rel="nofollow"
        >
          <h3 className="features-section__link-block__heading">
            Money back guarantee
          </h3>
          <p className="features-section__link-block__paragraph">
            Your money is safe. Even if we fail to satisfy your expectations, you can always request
            a refund and get your money back.
          </p>
          <div className="read-more">
            <span className="read-more__text" data-title="Read more">
              Read more
            </span>
          </div>
        </Link>
        <Link
          to="/private-writing-service.html"
          className="features-section__link-block features-section__confidentiality"
        >
          <h3 className="features-section__link-block__heading">
            Confidentiality
          </h3>
          <p className="features-section__link-block__paragraph">
            We don’t share your private information with anyone. What happens on our website stays
            on our website.
          </p>
          <div className="read-more">
            <span className="read-more__text" data-title="Read more">
              Read more
            </span>
          </div>
        </Link>
        <Link
          to="/legit-writing-service.html"
          className="features-section__link-block features-section__legit"
        >
          <h3 className="features-section__link-block__heading">
            Our service is legit
          </h3>
          <p className="features-section__link-block__paragraph">
            We provide you with a sample paper on the topic you need, and this kind of academic
            assistance is perfectly legitimate.
          </p>
          <div className="read-more">
            <span className="read-more__text" data-title="Read more">
              Read more
            </span>
          </div>
        </Link>
        <Link
          to="/plagiarism-free-writing-service.html"
          className="features-section__link-block features-section__plagiarism"
        >
          <h3 className="features-section__link-block__heading">
            Get a plagiarism-free paper
          </h3>
          <p className="features-section__link-block__paragraph">
            We check every paper with our plagiarism-detection software, so you get a unique paper
            written for your particular purposes.
          </p>
          <div className="read-more">
            <span className="read-more__text" data-title="Read more">
              Read more
            </span>
          </div>
        </Link>
        <Link
          to="/urgent-writing-service.html"
          className="features-section__link-block features-section__urgent"
        >
          <h3 className="features-section__link-block__heading">
            We can help with urgent tasks
          </h3>
          <p className="features-section__link-block__paragraph">
            Need a paper tomorrow? We can write it even while you’re sleeping. Place an order now
            and get your paper in 8 hours.
          </p>
          <div className="read-more">
            <span className="read-more__text" data-title="Read more">
              Read more
            </span>
          </div>
        </Link>
        <Link
          to="/cheap-writing-service.html"
          className="features-section__link-block features-section__price"
        >
          <h3 className="features-section__link-block__heading">
            Pay a fair price
          </h3>
          <p className="features-section__link-block__paragraph">
            Our prices depend on urgency. If you want a cheap essay, place your order in advance.
            Our prices start from $10 per page.
          </p>
          <div className="read-more">
            <span className="read-more__text" data-title="Read more">
              Read more
            </span>
          </div>
        </Link>

      </div>
    </div>
  </section>
);

export default OurFeaturesBlock;
