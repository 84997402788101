// @todo enable next eslint rules
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Link } from 'gatsby';
import VerticalTextBlock from '../VerticalTextBlock';

import './index.scss';

/**
 * @todo move presentation only images to stylesheets
 */
const GradesBlock = () => (
  <section className="grades-section">
    <div className="grades-section__container container">
      <VerticalTextBlock
        sectionClassName="grades"
        text="Grades"
        dotsStyle="style-1"
      />

      <div className="grades-section__content">
        <h2 className="grades-section__heading">
          We help you get the grades you need
        </h2>

        <div className="grades-section__wrap">
          <div className="grades-section__item">
            <div className="grades-section__item__heading">
              <div className="grades-section__item__bubble">
                <img
                  data-observed-image
                  data-src="/img/icons/bubble-1.svg"
                  width={147}
                  height={133}
                  alt="bubble"
                />
                <span>
                  73%
                </span>
              </div>
              <h3 className="grades-section__item__title">
                the average
                <br />
                {' '}
                grade we deliver
              </h3>
            </div>
            <p className="grades-section__item__paragraph">
              Choose your desired grade, and we will write a paper that meets all the requirements
              to achieve it. Moreover, our
              {' '}
              <Link to="/quality-assurance.html" rel="nofollow">
                Quality Assurance Department
              </Link>
              {' '}
              will check your paper using an evaluation scheme based on grading criteria from the
              top 70 US
              universities.
            </p>
          </div>
          <div className="grades-section__item">
            <div className="grades-section__item__heading">
              <div className="grades-section__item__bubble">
                <img
                  data-observed-image
                  data-src="/img/icons/bubble-2.svg"
                  width={159}
                  height={142}
                  alt="bubble"
                />
                <span>
                  531
                </span>
              </div>
              <h3 className="grades-section__item__title">
                writers from
                <br />
                {' '}
                the US
              </h3>
            </div>
            <p className="grades-section__item__paragraph">
              We have 4 writing hubs with specialists in business studies, STEM, social studies, and
              law. We make sure they are not only
              experts in their subjects, but know the ins and outs of academic writing.
            </p>
          </div>
          <div className="grades-section__item">
            <div className="grades-section__item__heading">
              <div className="grades-section__item__bubble">
                <img
                  data-observed-image
                  data-src="/img/icons/bubble-3.svg"
                  width={145}
                  height={146}
                  alt="bubble"
                />
                <span>
                  969k
                </span>
              </div>
              <h3 className="grades-section__item__title">
                original pages
                <br />
                {' '}
                written
              </h3>
            </div>
            <p className="grades-section__item__paragraph">
              As of April 2018, we’ve already written so many pages that if you print them all out
              and pile them up, they’ll be 94 meters
              high, which is almost the height of The Big Ben Tower. We know our job inside out and
              are ready to tackle your writing
              assignments.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default GradesBlock;
